import {
  Box,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PatientServicesHero from "../PatientServicesHero";

const PatientForms = () => {
  const forms = [
    {
      name: "New Patient Demographic Form",
      link: "http://www.atlantabehavioralcare.com/site/wp-content/uploads/2013/05/ABC-Registration-5-2013.pdf",
    },
    {
      name: "Patient Questionnaire",
      link: "http://www.atlantabehavioralcare.com/site/wp-content/uploads/2013/05/Welcome-Form-5-2013.pdf",
    },
    {
      name: "Patient Rights and Responsibilities",
      link: "http://www.atlantabehavioralcare.com/site/wp-content/uploads/2013/05/Patient-Rights-5-2013.pdf",
      info: "#rights",
    },
    {
      name: "HIPAA Patient Consent Form",
      link: "http://www.atlantabehavioralcare.com/site/wp-content/uploads/2013/05/hipaa-consent.pdf",
      info: "#hippa",
    },
    {
      name: "Medical Records Release Form",
      link: "http://www.atlantabehavioralcare.com/site/wp-content/uploads/2013/05/Confidential_Info_Release.pdf",
      info: "#release",
    },
  ];
  return (
    <Box>
      <PatientServicesHero />
      <Box sx={{ p: 5 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" textAlign="center" sx={{ pb: 3 }}>
            Patient Forms
          </Typography>
          <Typography variant="h5" sx={{ pb: 2 }}>
            New and Returning Patients
          </Typography>
          <Box sx={{ pb: 3 }}>
            <Typography variant="p">
              For your convenience, the following forms may be downloaded and
              completed prior to your scheduled appointment.
            </Typography>
          </Box>
          <Box sx={{ pb: 3 }}>
            <List>
              {forms.map((item) => (
                <Box display="flex" key={item.name}>
                  <ListItemButton href={item.link}>
                    <ListItemIcon>
                      <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItem>
                      <ListItemText primary={item.name} />
                    </ListItem>
                  </ListItemButton>
                </Box>
              ))}
            </List>
          </Box>
          <Typography variant="h5" sx={{ pb: 3 }}>
            Additional Information
          </Typography>
          <Typography variant="h6" sx={{ pb: 1 }}>
            HIPAA Patient Consent Form
          </Typography>
          <Typography variant="p">
            Each patient must sign a Patient Consent Form which gives Atlanta
            Behavioral Care permission to use protected healthcare information
            about you in order to carry out treatment, payment and general
            healthcare operations. Our HIPAA Notice of Privacy Practices Form
            describes how your medical information may be used or disclosed and
            gives the practice permission to use protected healthcare
            information about you in order to carry out treatment. Please review
            and sign this document. Review the{" "}
            <Link href="/hippaprivacyrules">HIPAA Privacy Rules</Link> page for
            more information about the practice’s patient privacy practices.
          </Typography>
          <Typography variant="h6" sx={{ pt: 5, pb: 1 }}>
            Medical Release of Information Form
          </Typography>
          <Typography variant="p">
            To request that medical information about you be sent to another
            physician, your employer or another entity, this form must be
            completed and signed. This allows us permission to release or obtain
            protected medical information on your behalf.
          </Typography>
          <Typography variant="h6" sx={{ pt: 5, pb: 1 }}>
            Fees for Special Information Requests
          </Typography>
          <Typography variant="p">
            Our practice receives many requests to complete various patient
            forms such as insurance, disability, FMLA, and handicapped parking.
            Completion of these forms requires medical expertise and a review of
            medical record documentation. For this reason, a fee based on
            complexity and length of the forms will be collected prior to
            releasing the form; no forms will be completed prior to payment of
            fees. It is the patient’s responsibility to complete their portion
            of the form and submit it to the requesting party.
          </Typography>
          <Box sx={{ py: 2 }}>
            <Typography variant="p">
              <i>
                Please refer to the fees indicated for applicable requests. If
                you mail the form to us, please make sure that you send payment
                with your request. No forms will be completed prior to payment
                of fees.
              </i>
            </Typography>
          </Box>
          <Box>
            <Typography variant="p">
              Fees for completion of forms will be determined at time of the
              visit; there is a minimum $50 fee.
            </Typography>
          </Box>
          <Typography variant="h6" sx={{ pt: 5, pb: 1 }}>
            Notice of Privacy Practices
          </Typography>
          <Box>
            <Typography variant="p">
              Atlanta Doctor's
              <Link href="/hippaprivacyrules">Notice of Privacy Practices</Link>
              describes how your medical information may be used or disclosed.
              Please review this document carefully. You may obtain a paper copy
              of this Notice upon request.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default PatientForms;
