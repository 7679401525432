import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PatientServicesHero from "../PatientServicesHero";

const MedicationRefill = () => {
  const info = [
    "Patient’s name and date of birth",
    "Caller’s name (if different) and phone number",
    "Drug name, dosage and how it is taken",
    "Pharmacy name and phone number",
  ];
  return (
    <Box>
      <PatientServicesHero />
      <Box sx={{ p: 5 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" textAlign="center" sx={{ pb: 3 }}>
            Medication Refills
          </Typography>
          <Typography variant="p">
            Call the office to request your prescription refill. We will need
            the following information:
          </Typography>
          <List>
            {info.map((item) => (
              <Box display="flex" key={item}>
                <ListItemButton sx={{ width: 5 }}>
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                </ListItemButton>
                <ListItem>
                  <ListItemText primary={item} />
                </ListItem>
              </Box>
            ))}
          </List>
          <Box>
            <Typography variant="p">
              Refills are processed within 48 hours of the request. Contact your
              pharmacy directly to check on the status of the medication refill.
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="p">
              Calls after 12:00 noon on Fridays or the day before a holiday may
              not be addressed until the next business day.
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="p">
              Calls after 12:00 noon on Fridays or the day before a holiday may
              not be addressed until the next business day.
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="p">
              The office does not call in medication refills except under
              extreme emergencies. Running out of your medication is not
              considered an emergency by the providers in this office. Under no
              circumstances are controlled substances called in; these
              prescriptions are handled during appointments only.
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="p">
              Please ask your doctor when you should return – and schedule your
              next appointment before you leave the office.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default MedicationRefill;
