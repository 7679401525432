import { Link, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import PatientServicesHero from "../PatientServicesHero";

const HIPPA = () => {
  return (
    <Box>
      <PatientServicesHero />
      <Box sx={{ p: 5 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" textAlign="center" sx={{ pb: 3 }}>
            HIPAA Privacy Rules for the Protection of Health and Mental Health
            Information
          </Typography>
          <p>
            As required by the privacy regulations created as a result of the
            Health Insurance Portability and Accountability Act of 1996 (HIPAA).
          </p>
          <p>
            This notice describes how health information about you (as a patient
            of this practice) may be used and disclosed and how you can get
            access to your individually identifiable health information.
            Additional information can be found{" "}
            <Link href="https://www.hhs.gov/hipaa/index.html">here</Link>.
          </p>
          <p>Please review this notice carefully.</p>
          <Typography variant="h5">Our Commitment to Your Privacy</Typography>
          <p>
            Our practice is dedicated to maintaining the privacy of your
            individually identifiable health information (also called protected
            health information, or PHI). In conducting our business, we will
            create records regarding you and the treatment and services we
            provide to you. We are required by law to maintain the
            confidentiality of health information that identifies you. We also
            are required by law to provide you with this notice of our legal
            duties and the privacy practices that we maintain in our practice
            concerning your PHI. By federal and state law, we must follow the
            terms of the Notice of Privacy Practices that we have in effect at
            the time.
          </p>
          <p>
            We realize that these laws are complicated, but we must provide you
            with the following important information:
          </p>
          <ol>
            <li>How we may use and disclose your PHI,</li>
            <li>Your privacy rights in your PHI,</li>
            <li>
              Our obligations concerning the use and disclosure of your PHI.
            </li>
          </ol>
          <p>
            The terms of this notice apply to all records containing your PHI
            that are created or retained by our practice. We reserve the right
            to revise or amend this Notice of Privacy Practices. Any revision or
            amendment to this notice will be effective for all of your records
            that our practice has created or maintained in the past, and for any
            of your records that we may create or maintain in the future. Our
            practice will post a copy of our current
          </p>
          <p>
            Notice in our offices in a visible location at all times, and you
            may request a copy of our most current Notice at any time.
          </p>
          <Typography variant="h5">Use and Disclosure of Your PHI</Typography>
          <p>
            The following categories describe the different ways in which we may
            use and disclose your PHI:
          </p>
          <p>Effective Date: 4.14.2003</p>
          <ol>
            <li>
              <strong>Treatment. </strong>Our practice may use your PHI to treat
              you. For example, we may ask you to have laboratory tests (such as
              blood or urine tests), and we may use the results to help us reach
              a diagnosis. We might use your PHI in order to write a
              prescription for you, or we might disclose your PHI to a pharmacy
              when we order a prescription for you. Many of the people who work
              for our practice – including, but not limited to, our doctors and
              nurses – may use or disclose your PHI in order to treat you or to
              assist others in your treatment. Additionally, we may disclose
              your PHI to others who may assist in your care, such as your
              spouse, children or parents. Finally, we may also disclose your
              PHI to other health care providers for purposes related to your
              treatment.
            </li>
            <li>
              <strong>Payment. </strong>Our practice may use and disclose your
              PHI in order to bill and collect payment for the services and
              items you may receive from us. For example, we may contact your
              health insurer to certify that you are eligible for benefits (and
              for what range of benefits), and we may provide your insurer with
              details regarding your treatment to determine if your insurer will
              cover, or pay for, your treatment. We also may use and disclose
              your PHI to obtain payment from third parties that may be
              responsible for such costs, such as family members. Also, we may
              use your PHI to bill you directly for services and items. We may
              disclose your PHI to other health care providers and entities to
              assist in their billing and collection efforts.
            </li>
            <li>
              <strong>Health care operations. </strong>Our practice may use and
              disclose your PHI to operate our business. As examples of the ways
              in which we may use and disclose your information for our
              operations, our practice may use your PHI to evaluate the quality
              of care you received from us, or to conduct cost-management and
              business planning activities for our practice. We may disclose
              your PHI to other health care providers and entities to assist in
              their health care operations.
            </li>
            <li>
              <strong>Appointment reminders. </strong>Our practice may use and
              disclose your PHI to contact you and remind you of an appointment.
            </li>
            <li>
              <strong>Treatment options. </strong>Our practice may use and
              disclose your PHI to inform you of potential treatment options or
              alternatives.
            </li>
            <li>
              <strong>Health-related benefits and services. </strong>Our
              practice may use and disclose your PHI to inform you of
              health-related benefits or services that may be of interest to
              you.
            </li>
            <li>
              <strong>Release of information to family/friends. </strong>Our
              practice may release your PHI to a friend or family member who is
              involved in your care, or who assists in taking care of you. For
              example, a parent or guardian may ask that a baby sitter take
              their child to the pediatrician’s office for treatment of a cold.
              In this example, the baby sitter may have access to this child’s
              medical information.
            </li>
            <li>
              <strong>Disclosures required by law. </strong>Our practice will
              use and disclose your PHI when we are required to do so by
              federal, state or local law.
            </li>
          </ol>
          <Typography variant="h5">
            Use and Disclosure of Your PHI in Certain Special Circumstances
          </Typography>
          <p>
            The following categories describe unique scenarios in which we may
            use or disclose your identifiable health information:
          </p>
          <ol>
            <li>
              <strong>Public health risks. </strong>Our practice may disclose
              your PHI to public health authorities that are authorized by law
              to collect information for the purpose of:
            </li>
            <ol type="a">
              <li>Maintaining vital records, such as births and deaths;</li>
              <li>Reporting child abuse or neglect;</li>
              <li>Preventing or controlling disease, injury or disability;</li>
              <li>
                Notifying a person regarding potential exposure to a
                communicable disease;
              </li>
              <li>
                Notifying a person regarding a potential risk for spreading or
                contracting a disease or condition;
              </li>
              <li>
                Reporting reactions to drugs or problems with products or
                devices;
              </li>
              <li>
                Notifying individuals if a product or device they may be using
                has been recalled;
              </li>
              <li>
                Notifying appropriate government agency and authority regarding
                the potential abuse or neglect of an adult patient (including
                domestic violence); however, we will only disclose this
                information if the patient agrees or we are required or
                authorized by law to disclose this information; and
              </li>
              <li>
                Notifying your employer under limited circumstances related
                primarily to workplace injury or illness or medical
                surveillance.
              </li>
            </ol>
            {/* <ol> */}
            <li>
              <strong>Health oversight activities. </strong>Our practice may
              disclose your PHI to a health oversight agency for activities
              authorized by law. Oversight activities can include, for example,
              investigations, inspections, audits, surveys, licensure and
              disciplinary actions; civil, administrative and criminal
              procedures or actions; or other activities necessary for the
              government to monitor government programs, compliance with civil
              rights laws and the health care system in general.
            </li>
            <li>
              <strong>Lawsuits and similar proceedings. </strong>Our practice
              may use and disclose your PHI in response to a court or
              administrative order, if you are involved in a lawsuit or similar
              proceeding. We also may disclose your PHI in response to a
              discovery request, subpoena or other lawful process by another
              party involved in the dispute, but only if we have made an effort
              to inform you of the request or to obtain an order protecting the
              information the party has requested.
              <ol type="a">
                <li>
                  <strong>Law Enforcement. </strong>We may release PHI if asked
                  to do so by a law enforcement official:
                </li>
                <ol type="i">
                  <li>
                    Regarding a crime victim in certain situations, if we are
                    unable to obtain the person’s agreement;
                  </li>
                  <li>
                    Concerning a death we believe has resulted from criminal
                    conduct;
                  </li>
                  <li>Regarding criminal conduct at our offices;</li>
                  <li>
                    In response to a warrant, summons, court order, subpoena or
                    similar legal process;
                  </li>
                  <li>
                    To identify/locate a suspect, material witness, fugitive or
                    missing person; and
                  </li>
                  <li>
                    In an emergency, to report a crime (including the location
                    or victim(s) of the crime, or the description, identity or
                    location of the perpetrator).
                  </li>
                </ol>
              </ol>
            </li>
            <li>
              <strong>Deceased patients. </strong>Our practice may release PHI
              to a medical examiner or coroner to identify a deceased individual
              or to identify the cause of death. If necessary, we also may
              release information in order for funeral directors to perform
              their jobs.
            </li>
            <li>
              <strong>Organ and tissue donation. </strong>Our practice may
              release your PHI to organizations that handle organ, eye or tissue
              procurement or transplantation, including organ donation banks, as
              necessary to facilitate organ or tissue donation and
              transplantation if you are an organ donor.
            </li>
            <li>
              <strong>Research. </strong>Our practice may use and disclose your
              PHI for research purposes in certain limited circumstances. We
              will obtain your written authorization to use your PHI for
              research purposes except when an Internal Review Board or Privacy
              Board has determined that the waiver of your authorization
              satisfies all of the following conditions:
              <ol type="a">
                <li>
                  The use or disclosure involves no more than a minimal risk to
                  your privacy based on the following:
                  <ol type="i">
                    <li>
                      an adequate plan to protect the identifiers from improper
                      use and disclosure;
                    </li>
                    <li>
                      an adequate plan to destroy the identifiers at the
                      earliest opportunity consistent with the research (unless
                      there is a health or research justification for retaining
                      the identifiers or such retention is otherwise required by
                      law); and
                    </li>
                    <li>
                      adequate written assurances that the PHI will not be
                      re-used or disclosed to any other person or entity (except
                      as required by law) for authorized oversight of the
                      research study, or for other research for which the use or
                      disclosure would otherwise be permitted;
                    </li>
                  </ol>
                  <li>
                    The research could not practicably be conducted without the
                    waiver; or
                  </li>
                  <li>
                    The research could not practicably be conducted without
                    access to and use of the PHI.
                  </li>
                </li>
              </ol>
            </li>
            <li>
              <strong>Serious threats to health or safety. </strong>Our practice
              may use and disclose your PHI when necessary to reduce or prevent
              a serious threat to your health and safety or the health and
              safety of another individual or the public. Under these
              circumstances, we will only make disclosures to a person or
              organization able to help prevent the threat.
            </li>
            <li>
              <strong>Military. </strong>Our practice may disclose your PHI if
              you are a member of U.S. or foreign military forces (including
              veterans) and if required by the appropriate authorities.
            </li>
            <li>
              <strong>National security. </strong>Our practice may disclose your
              PHI to federal officials for intelligence and national security
              activities authorized by law. We also may disclose your PHI to
              federal and national security activities authorized by law. We
              also may disclose your PHI to federal officials in order to
              protect the president, other officials or foreign heads of state,
              or to conduct investigations.
            </li>
            <li>
              <strong>Inmates. </strong>Our practice may disclose your PHI to
              correctional institutions or law enforcement officials if you are
              an inmate or under the custody of a law enforcement official.
              Disclosure for these purposes would be necessary:
              <ol type="a">
                <li>
                  for the institution to provide health care services to you,
                </li>
                <li>for the safety and security of the institution, and/or</li>
                <li>
                  to protect your health and safety or the health and safety of
                  other individuals
                </li>
              </ol>
            </li>
            <li>
              <strong>Workers’ compensation. </strong>Our practice may release
              your PHI for workers’ compensation and similar programs.
            </li>
          </ol>
          {/* </ol> */}
          <Typography variant="h5">Your Rights Regarding Your PHI</Typography>
          <p>
            <i>
              You have the following rights regarding the PHI that we maintain
              about you:
            </i>
          </p>
          <ol>
            <li>
              <strong>Confidential communications. </strong>You have the right
              to request that our practice communicate with you about your
              health and related issues in a particular manner or at a certain
              location. For instance, you may ask that we contact you at home,
              rather than work. In order to request a type of confidential
              communication, you must make a written request to Atlanta
              Behavioral Care specifying the requested method of contact, or the
              location where you wish to be contacted. Our practice will
              accommodate reasonable requests. You do not need to give a reason
              for your request.
            </li>
            <li>
              <strong>Requesting restrictions. </strong>You have the right to
              request a restriction in our use or disclosure of your PHI for
              treatment, payment or health care operations. Additionally, you
              have the right to request that we restrict our disclosure of your
              PHI to only certain individuals involved in your care or the
              payment for your care, such as family members and friends. We are
              not required to agree to your request; however, if we do agree, we
              are bound by our agreement except when otherwise required by law,
              in emergencies or when the information is necessary to treat you.
              In order to request a restriction in our use or disclosure of your
              PHI, you must make your request in writing to Atlanta Behavioral
              Care. Your request must describe in a clear and concise fashion:
              <ol type="a">
                <li>The information you wish restricted;</li>
                <li>
                  Whether you are requesting to limit our practice’s use,
                  disclosure or both; and
                </li>
                <li>To whom you want the limits to apply.</li>
              </ol>
            </li>
            <li>
              <strong>Inspection and copies. </strong>You have the right to
              inspect and obtain a copy of the PHI that may be used to make
              decisions about you, including patient medical records and billing
              records, but not including psychotherapy notes. You must submit
              your request in writing to us in order to inspect and/or obtain a
              copy of your PHI. Our practice may charge a fee for the costs of
              copying, mailing, labor and supplies associated with your request.
              Our practice may deny your request to inspect and/or copy in
              certain limited circumstances; however, you may request a review
              of our denial. Another licensed healthcare professional chosen by
              us will conduct reviews.
            </li>
            <li>
              <strong>Amendment. </strong>You may ask us to amend your health
              information if you believe it is incorrect or incomplete,and you
              may request an amendment for as long as the information is kept by
              or for our practice. To request an amendment, your request must be
              made in writing and submitted to us. You must provide us with a
              reason that supports your request for amendment. Our practice will
              deny your request if you fail to submit your request in writing.
              Also, we may deny your request if you ask us to amend information
              that is in our opinion:
              <ol type="a">
                <li>accurate and complete;</li>
                <li>not part of the PHI kept by or for the practice;</li>
                <li>
                  not part of the PHI which you would be permitted to inspect
                  and copy; or
                </li>
                <li>
                  not created by our practice, unless the individual or entity
                  that created the information is not available to amend the
                  information.
                </li>
              </ol>
            </li>
            <li>
              <strong>Accounting of disclosures. </strong>All of our patients
              have the right to request an “accounting of disclosures.” An
              “accounting of disclosures” is a list of certain non-routine
              disclosures our practice has made of your PHI for purposes not
              related to treatment, payment or operations. Use of your PHI as
              part of the routine patient care in our practice is not required
              to be documented – for example, the doctor sharing information
              with the nurse; or the billing department using your information
              to file your insurance claim. In order to obtain an accounting of
              disclosures, you must submit your request in writing to Atlanta
              Behavioral Care. All requests for an “accounting of disclosures”
              must state a time period, which may not be longer than six (6)
              years from the date of disclosure and may not include dates before
              April 14, 2003. The first list you request within a 12-month
              period is free of charge, but our practice may charge you for
              additional lists within the same 12-month period. Our practice
              will notify you of the costs involved with additional requests,
              and you may withdraw your request before you incur any costs.S
            </li>
            <li>
              <strong>Right to a paper copy of this notice. </strong>You are
              entitled to receive a paper copy of our notice of privacy
              practices. You may ask us to give you a copy of this notice at any
              time. To obtain a paper copy of this notice, contact Atlanta
              Behavioral Care.
            </li>
            <li>
              <strong>Right to file a complaint. </strong>If you believe your
              privacy rights have been violated, you may file a complaint with
              our practice or with the Secretary of the Department of Health and
              Human Services. To file a complaint with our practice, contact us.
              All complaints must be submitted in writing. You will not be
              penalized for filing a complaint.
            </li>
            <li>
              Right to provide an authorization for other uses and disclosures.
              Our practice will obtain your written authorization for uses and
              disclosures that are not identified by this notice or permitted by
              applicable law. Any authorization you provide to us regarding the
              use and disclosure of your PHI may be revoked at any time in
              writing. After you revoke your authorization, we will no longer
              use or disclose your PHI for the reasons described in the
              authorization. Please note: we are required to retain records of
              your care.
            </li>
          </ol>
          <h4>
            <strong>
              Again, if you have any questions regarding this notice or our
              health information privacy policies, please contact Atlanta
              Behavioral Care.
            </strong>
          </h4>
        </Container>
      </Box>
    </Box>
  );
};

export default HIPPA;
