import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Checkmark from "../assets/icons/secondary-checkmark-48x48.png";

const OurServices = () => {
  const services = [
    { icon: Checkmark, name: "Psychopharmacology" },
    { icon: Checkmark, name: "Alcohol and chemical dependency" },
    { icon: Checkmark, name: "Suboxone Ambulatory Detoxification" },
    { icon: Checkmark, name: "Anger management" },
    { icon: Checkmark, name: "Psychological testing for adults and children" },
    {
      icon: Checkmark,
      name: "Psychiatric evaluations and substance abuse evaluations",
    },
    {
      icon: Checkmark,
      name: "Specialized evaluations needed prior to surgery and other medical treatments",
    },
    {
      icon: Checkmark,
      name: "Individual, marital and family psychotherapy and EMDR Treatment",
    },
  ];
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container maxWidth="lg">
        <Typography variant="h3" sx={{ textAlign: "center", p: 2 }}>
          Some of Our Services
        </Typography>
        <Grid container spacing={2}>
          {services.map((item) => (
            <Grid item md={6} xs={12} key={item.name}>
              <List>
                <ListItem>
                  <img alt="checkmark" src={item.icon} />
                  <Typography variant="h5" sx={{ p: 2 }}>
                    {item.name}
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default OurServices;
