import { Grid, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <Box height="25vh" sx={{ backgroundColor: "#e0e0e0" }}>
      <Grid
        sx={{
          justifyContent: "center",
          display: "flex",
          textAlign: "center",

          p: 3,
        }}
        alignItems="center"
        height="100%"
      >
        <Grid item>
          <Box height="100%" width="100%">
            <Link underline="hover" href="https://www.wesgarvin.dev">
              by Wes Garvin
            </Link>
            <Typography variant="body2">
              Copywrite @ {year} Atlanta Doctor
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
