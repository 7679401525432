import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const MedicalTeamHero = () => {
  return (
    <Box className="medical-team-hero">
      <div className="overlay">
        <Grid
          container
          alignItems="center"
          className="content"
          sx={{ justifyContent: "center" }}
        >
          <Grid item>
            <Typography
              variant="h2"
              color="white"
              textAlign="center"
              sx={{ pb: 2 }}
            >
              Medical Team
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default MedicalTeamHero;
