import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import medicalteam from "../data/medicalteam.js";

const MedicalTeamCards = () => {
  return (
    <Box sx={{ py: 5 }}>
      <Container maxWidth="lg">
        {medicalteam.map((item) => (
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              padding: 1,
              width: "100%",
            }}
            key={item.name}
          >
            <Card
              sx={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
                boxShadow: 10,
              }}
            >
              <CardActionArea
                sx={{
                  display: "flex",
                }}
              >
                <Box>
                  <CardMedia
                    component="img"
                    sx={{
                      backgroundColor: "primary.light",
                      p: 2,
                    }}
                    image={item.profile}
                    alt="portrait"
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {item.name}
                    </Typography>
                    <Typography variant="h6" component="div">
                      {item.role}
                    </Typography>
                  </CardContent>
                </Box>
              </CardActionArea>
            </Card>
          </Box>
        ))}
        <Typography variant="body2" sx={{ pt: 4 }}>
          *The doctors and other health care professionals in the practice are
          not employed by us, but are rather affiliated professional health care
          providers who may work at other practices​​.
        </Typography>
      </Container>
    </Box>
  );
};

export default MedicalTeamCards;
