import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Contact = () => {
  const officeHours = [
    { hours: "Monday-Friday: 9am-6:30pm" },
    { hours: "Satruday: 10am-4pm" },
    { hours: "Sunday: Closed" },
  ];
  return (
    <Box id="contact" sx={{ backgroundColor: "primary.main", width: "100%" }}>
      <Grid
        container
        sx={{
          display: { xs: "block", xl: "flex" },
        }}
        alignItems="center"
      >
        <Grid
          item
          className="grid-item"
          sx={{
            width: { xs: "100%", xl: "40%" },
            height: { xs: "100%" },
          }}
        >
          <Box sx={{ p: 5 }}>
            <Typography variant="h3" color="secondary">
              Contact Us
            </Typography>
            <Typography variant="h5" color="white">
              P: 555-555-5555
            </Typography>
            <Typography variant="h5" color="white">
              F: 555-555-5555
            </Typography>
            <Typography variant="h5" color="secondary">
              7446 Canal St. Atlanta, GA 30213
            </Typography>
            {officeHours.map((item) => (
              <Typography variant="h6" color="white" key={item.hours}>
                {item.hours}
              </Typography>
            ))}
            <p>Appointments are scheduled by calling the office.</p>
            <p>
              If you need to contact us after hours, on weekends or holidays,
              call the office and you will automatically be forwarded to the
              answering service for assistance.
            </p>
            <p>
              If you are experiencing a life threatening emergency, call 911 or
              go to your nearest emergency room.
            </p>
            <Box
              sx={{ display: { xs: "flex", md: "none" }, p: 2 }}
              justifyContent="center"
            >
              <Button
                variant="contained"
                sx={{ backgroundColor: "secondary.main", fontSize: "30px" }}
                href="tel:7703196000"
              >
                CALL
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          className="grid-item"
          sx={{
            width: { xs: "100%", xl: "60%" },
            height: { xs: "50vh", xl: "75vh" },
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13269.781746480408!2d-84.3881408!3d33.7491536!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5033eabe0e591%3A0xcb22068d5049fb8d!2sGeorgia%20Capitol%20Museum!5e0!3m2!1sen!2sus!4v1678129077424!5m2!1sen!2sus"
            width="100%"
            height="100%"
            loading="lazy"
            title="Google Maps"
          ></iframe>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
