import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const PatientServicesHero = () => {
  return (
    <Box className="patient-services-hero">
      <div className="overlay">
        <Grid
          container
          alignItems="center"
          className="content"
          sx={{ justifyContent: "center" }}
        >
          <Grid item>
            <Typography
              variant="h2"
              color="white"
              textAlign="center"
              sx={{ pb: 2 }}
            >
              Patient Services
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default PatientServicesHero;
