import "./App.css";
import Navbar from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import MedicalTeam from "./pages/MedicalTeam";
import PatientServices from "./pages/PatientServices";
import InsurancePayment from "./components/patient-services/InsurancePayment";
import MedicalRecords from "./components/patient-services/MedicalRecords";
import MedicationRefill from "./components/patient-services/MedicationRefill";
import PatientForms from "./components/patient-services/PatientForms";
import Prepare from "./components/patient-services/Prepare";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import HIPPA from "./components/patient-services/HIPPA";
import Footer from "./components/Footer";
import Contact from "./components/Contact";

const theme = createTheme({
  palette: {
    primary: {
      light: "#7fa89d",
      main: "#52796f",
      dark: "#274d44",
    },
    secondary: {
      light: "#ffeac3",
      main: "#ddb892",
      dark: "#aa8864",
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/medicalteam" element={<MedicalTeam />} />
          <Route path="/patientservices" element={<PatientServices />} />
          <Route path="/insurancepayment" element={<InsurancePayment />} />
          <Route path="/medicalrecords" element={<MedicalRecords />} />
          <Route path="/medicationrefill" element={<MedicationRefill />} />
          <Route path="/patientforms" element={<PatientForms />} />
          <Route path="/prepare" element={<Prepare />} />
          <Route path="/hippa" element={<HIPPA />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
