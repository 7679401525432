import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import Link from "@mui/material/Link";
import PatientServicesHero from "../PatientServicesHero";

const MedicalRecords = () => {
  const fees = [
    {
      title: "Search, retrieval, and other direct administrative costs",
      note: "Up to:",
      cost: "$25.88",
    },
    { title: "Certification fee", note: "Up to per record:", cost: "$9.70" },
  ];

  const paperFees = [
    { title: "Paper Fees", note: "1-20", cost: "$0.97" },
    { title: "Paper Fees", note: "21-100", cost: "$0.83" },
    { title: "Paper Fees", note: "Over 100", cost: "$0.66" },
  ];
  return (
    <Box>
      <PatientServicesHero />
      <Box sx={{ p: 5 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" textAlign="center" sx={{ pb: 3 }}>
            Medical Records
          </Typography>
          <Typography variant="p">
            When requesting that your medical records be released, please be
            aware that certain fees may apply.
          </Typography>
          <Box>
            <br />
            <Typography variant="p">
              Georgia’s regulated medical record fees as posted on the Georgia
              Unannotated Code 31-33-3 states:
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="p">
              “The party requesting patient’s records shall be responsible to
              the provider for the reasonable costs of copying and mailing the
              patient’s record. The actual cost of postage incurred in mailing
              the requested records may also be charged.”
            </Typography>
          </Box>
          <br />
          {/* Regular Fees */}
          <Box sx={{ pt: 5 }}>
            <Typography variant="h5">
              Current Rates for Record Requests
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Note</TableCell>
                    <TableCell>Cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fees.map((item) => (
                    <TableRow key={item.title} hover={true}>
                      <TableCell>{item.title}</TableCell>
                      <TableCell>{item.note}</TableCell>
                      <TableCell>{item.cost}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {/* Paper Fees */}
          <Box sx={{ pt: 5 }}>
            <Typography variant="h5">
              Copying Costs For Records In Paper Form
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Per Page</TableCell>
                    <TableCell>Cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paperFees.map((item) => (
                    <TableRow key={item.note} hover={true}>
                      <TableCell>{item.title}</TableCell>
                      <TableCell>{item.note}</TableCell>
                      <TableCell>{item.cost}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ pt: 5 }}>
            <Typography variant="h6">
              Note: Rates do not apply to records requests necessary to make or
              complete an application for a disability benefits program.
            </Typography>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Link href="http://www.atlantabehavioralcare.com/files/Confidential_Info_Release.pdf">
              Medical Records Release Form
            </Link>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default MedicalRecords;
