import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "../App.css";

const AboutUs = () => {
  return (
    <Box className="about-us" sx={{ height: { xs: "100%", xl: "50vh" } }}>
      <div className="overlay">
        <Grid container className="content" alignItems="center">
          <Grid item>
            <Box height="100%">
              <Typography
                variant="h4"
                color="white"
                sx={{ p: 5 }}
                textAlign="center"
              >
                We are a multi-disciplinary group of psychiatrists,
                psychologists, master's level therapists, psychiatric nurses,
                and addiction counselors, employed or otherwise affiliated with
                us, who have been providing high quality behavioral health
                services to children, adolescents, adults and geriatric
                populations since 1989.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default AboutUs;
