import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import "../App.css";

const Hero = () => {
  return (
    <Box>
      <Paper className="section">
        <div className="overlay">
          {" "}
          <Grid
            className="content"
            container
            alignItems="center"
            sx={{ px: 5 }}
          >
            <Grid item>
              <Typography component="h1" variant="h2" color="white">
                Your health is our top priority.
              </Typography>
              <Typography
                component="h2"
                variant="h5"
                color="white"
                sx={{ my: 1 }}
              >
                Contact us today to find out how we can serve you.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Box>
  );
};

export default Hero;
