import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PatientServicesHero from "../components/PatientServicesHero";
import Dollar from "../assets/icons/patient-services-dollar.png";
import Records from "../assets/icons/patient-services-records.png";
import Pills from "../assets/icons/patient-services-pills.png";
import Pencil from "../assets/icons/patient-services-pencil.png";
import Visit from "../assets/icons/patient-services-visit.png";
import Lock from "../assets/icons/patient-services-lock.png";

const PatientServices = () => {
  const services = [
    { name: "Insurance & Payment", route: "/insurancepayment", icon: Dollar },
    { name: "Medical Records", route: "/medicalrecords", icon: Records },
    { name: "Medication Refills", route: "/medicationrefill", icon: Pills },
    { name: "Patient Forms", route: "/patientforms", icon: Pencil },
    { name: "Preparing for Your Visit", route: "/prepare", icon: Visit },
    { name: "HIPPA Privacy Rules", route: "/hippa", icon: Lock },
  ];
  return (
    <Box>
      <PatientServicesHero />
      <Box sx={{ py: 5 }}>
        <Container maxWidth="lg">
          {services.map((item) => (
            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                padding: 1,
                width: "100%",
              }}
              key={item.name}
            >
              <Card
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  boxShadow: 10,
                }}
              >
                <CardActionArea
                  href={item.route}
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box>
                    <CardMedia
                      component="img"
                      sx={{
                        backgroundColor: "primary.light",
                        p: 2,
                      }}
                      image={item.icon}
                      alt="serviceicon"
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <CardContent>
                      <Typography variant="h5" component="div">
                        {item.name}
                      </Typography>
                    </CardContent>
                  </Box>
                </CardActionArea>
              </Card>
            </Box>
          ))}
        </Container>
      </Box>
    </Box>
  );
};

export default PatientServices;
