import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import PatientServicesHero from "../PatientServicesHero";

const Prepare = () => {
  const prepare = [
    "Please arrive 15 minutes before your scheduled appointment to complete all necessary information.",
    "Make sure your physician knows all the medications you are taking. This includes prescription and over-the-counter medicines, as well as dietary supplements such as vitamins and herbs.",
    "Make sure your doctor knows about any allergies and adverse reactions you have had to medicines. This will help you avoid getting a medicine that can harm you.",
    "If you have any questions about the directions on your medicine label, ask. For example, if a label says “four doses daily”, find out if that means once every six hours, or just during regular waking hours.",
    "Ask for information about possible side effects of medicines prescribed to you. If you know what might happen, you will be better prepared if it does, or if something unexpected happens instead.",
    "If you have tests performed  – and you don’t hear from us within five (5) days, call the office.",
  ];
  return (
    <Box>
      <PatientServicesHero />
      <Box sx={{ p: 5 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" textAlign="center" sx={{ pb: 3 }}>
            Preparing for your office visit
          </Typography>
          <Typography variant="h5">
            To make your office visit go more smoothly and receive the best
            possible care at Atlanta Doctor:
          </Typography>
          <List>
            {prepare.map((item) => (
              <Box display="flex" key={item}>
                <ListItemButton sx={{ width: 5 }}>
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                </ListItemButton>
                <ListItem>
                  <ListItemText primary={item} />
                </ListItem>
              </Box>
            ))}
          </List>
          <Typography variant="h5" sx={{ pt: 3, pb: 1 }}>
            Appointment Cancellations
          </Typography>
          <Typography variant="p">
            If you need to reschedule or cancel an appointment, we require a
            minimum cancellation notice of 24 hours. A $50 fee will be charged
            when a patient fails to provide us with at least 24 hours’ notice of
            cancellation. Please remember that confirmation reminders from us
            are only a courtesy. Our failure to confirm your visit does not
            relieve you of your responsibility to cancel your appointment.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Prepare;
