import ProfileIcon from "../assets/icons/profile-icon-100x100.png";

const medicalteam = [
  {
    name: "Julie Bertrando, M.D.",
    role: "Psychiatrist",
    profile: ProfileIcon,
    education: [
      "Yale College – B.S. in Psychology and B.S. in Molecular Biophysic and Biochemistry",
      "Yale University of Medicine – Postdoctoral Fellowship in Psychiatry",
      "Emory University School of Medicine – Fellowship in Neurology",
    ],
  },
  {
    name: "Daryl Rice, Jr., M.D.*",
    role: "Psychiatrist",
    profile: ProfileIcon,
    education: [
      "Yale College – B.S. in Psychology and B.S. in Molecular Biophysic and Biochemistry",
      "Yale University of Medicine – Postdoctoral Fellowship in Psychiatry",
      "Emory University School of Medicine – Fellowship in Neurology",
    ],
  },
  {
    name: "Sean Pratt, M.D.*",
    role: "Psychiatrist",
    profile: ProfileIcon,
    education: [
      "Yale College – B.S. in Psychology and B.S. in Molecular Biophysic and Biochemistry",
      "Yale University of Medicine – Postdoctoral Fellowship in Psychiatry",
      "Emory University School of Medicine – Fellowship in Neurology",
    ],
  },
  {
    name: "Jamie Morton, M.D.*",
    role: "Psychiatrist",
    profile: ProfileIcon,
    education: [
      "Yale College – B.S. in Psychology and B.S. in Molecular Biophysic and Biochemistry",
      "Yale University of Medicine – Postdoctoral Fellowship in Psychiatry",
      "Emory University School of Medicine – Fellowship in Neurology",
    ],
  },
  {
    name: "Jerome Perkins, Psy.D.*",
    role: "Psychologist",
    profile: ProfileIcon,
    education: [
      "Yale College – B.S. in Psychology and B.S. in Molecular Biophysic and Biochemistry",
      "Yale University of Medicine – Postdoctoral Fellowship in Psychiatry",
      "Emory University School of Medicine – Fellowship in Neurology",
    ],
  },
  {
    name: "Patti Barber, L.P.C.*",
    role: "Licensed Professional Counselor",
    profile: ProfileIcon,
    education: [
      "Yale College – B.S. in Psychology and B.S. in Molecular Biophysic and Biochemistry",
      "Yale University of Medicine – Postdoctoral Fellowship in Psychiatry",
      "Emory University School of Medicine – Fellowship in Neurology",
    ],
  },
  {
    name: "Craig Morrison, R.N., L.C.S.W.*",
    role: "Licensed Clinical Social Worker",
    profile: ProfileIcon,
    education: [
      "Yale College – B.S. in Psychology and B.S. in Molecular Biophysic and Biochemistry",
      "Yale University of Medicine – Postdoctoral Fellowship in Psychiatry",
      "Emory University School of Medicine – Fellowship in Neurology",
    ],
  },
  {
    name: "Brendan Sandoval, L.C.S.W.*",
    role: "Clinical Social Worker",
    profile: ProfileIcon,
    education: [
      "Yale College – B.S. in Psychology and B.S. in Molecular Biophysic and Biochemistry",
      "Yale University of Medicine – Postdoctoral Fellowship in Psychiatry",
      "Emory University School of Medicine – Fellowship in Neurology",
    ],
  },
  {
    name: "Eileen Sullivan, M.D.*",
    role: "Nurse Practitioner",
    profile: ProfileIcon,
    education: [
      "Yale College – B.S. in Psychology and B.S. in Molecular Biophysic and Biochemistry",
      "Yale University of Medicine – Postdoctoral Fellowship in Psychiatry",
      "Emory University School of Medicine – Fellowship in Neurology",
    ],
  },
];

export default medicalteam;
