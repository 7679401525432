import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  ListItemIcon,
} from "@mui/material";
import { Box } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";

const Navbar = () => {
  const [drawerToggle, setDrawerToggle] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerToggle(true);
  };

  const handleDrawerClose = () => {
    setDrawerToggle(false);
  };

  const pages = [
    { name: "Home", link: "/" },
    { name: "Patient Services", link: "/patientservices" },
    { name: "Medical Team", link: "/medicalteam" },
    { name: "Contact", link: "/contact" },
  ];
  return (
    <AppBar className="navbar" position="sticky" elevation={0}>
      <Toolbar>
        <Typography variant="h3" color="white" sx={{ flexGrow: 1 }}>
          Atlanta Doctor
        </Typography>

        {/* Desktop */}
        <Box display={{ xs: "none", lg: "block" }}>
          {pages.map((page) => (
            <Button
              variant="text"
              color="inherit"
              key={page.name}
              href={page.link}
              sx={{ mx: 0, my: 2, p: 2, fontSize: 16 }}
            >
              {page.name}
            </Button>
          ))}
        </Box>

        {/* Mobile */}
        <Box display={{ xs: "block", lg: "none" }}>
          <IconButton color="inherit" onClick={handleDrawerOpen}>
            <MenuIcon fontSize="xl" />
          </IconButton>
        </Box>
        <Drawer
          PaperProps={{
            sx: {
              width: 250,
            },
          }}
          anchor="right"
          open={drawerToggle}
        >
          <List>
            <ListItemButton onClick={handleDrawerClose}>
              <ListItemIcon>
                <CloseIcon fontSize="xl" />
              </ListItemIcon>
            </ListItemButton>
            {pages.map((item) => (
              <ListItem disablePadding key={item.name}>
                <ListItemButton href={item.link}>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
