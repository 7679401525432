import {
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import PatientServicesHero from "../PatientServicesHero";

const InsurancePayment = () => {
  const info = [
    {
      title: "Co-payments, deductibles and co-insurance.",
      description:
        "All co-payments and deductibles must be paid at the time of service. If payment is not made at the time of service, there will be an additional charge of $25.00 for an administrative fee.",
    },
    {
      title: "Payment methods.",
      description:
        "Payment can be made by cash, check, debit card or credit card. Payment is expected at time of service unless prior arrangements have been made with the business office.",
    },
    {
      title: "Check Policy.",
      description:
        "All checks must be made out to Atlanta Doctor, and not to individual providers. A service charge of $40.00 will be assessed for each returned check. If we have received more than one returned check from your bank, we will no longer accept payment by check. You will be required to pay by credit card, cash or money order.",
    },
    {
      title: "Non-covered services.",
      description:
        "Please be aware that some – and perhaps all – of the services you receive may not be covered by your insurance plan. If you receive such services, you will be required to pay for these services in full at the time of visit.",
    },
    {
      title: "Proof of insurance.",
      description:
        "All patients must have a picture ID and an insurance card on file. If you cannot provide proof of insurance, you may be responsible for the claim.",
    },
    {
      title: "Claims submission.",
      description:
        "We will submit claims on your behalf to your insurance company for payment. Your insurance company may require that you supply certain information directly. It is your responsibility to coordinate your benefits with your carrier. Please be aware that the balance of your claim is your responsibility whether or not your insurance company pays your claim.",
    },
    {
      title: "Coverage changes.",
      description:
        "If your insurance changes, please notify us before your next visit so we can update your insurance information in our system. Providing us with this information prior to your visit will reduce your wait time. We will also do our best to verify your insurance coverage prior to your visit to minimize surprises at the time of visit.",
    },
    {
      title: "Missed appointments.",
      description:
        "Keeping your regularly scheduled appointment is important – for your health, for the convenience of other patients, and for more efficient operations at our office. If you must cancel or reschedule your appointment, please let us know 24 hours before your scheduled time to avoid a $50 cancellation fee.",
    },
  ];
  return (
    <Box>
      <PatientServicesHero />
      <Box sx={{ p: 5 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" textAlign="center" sx={{ pb: 3 }}>
            Insurance & Payment
          </Typography>
          <Typography variant="p">
            We are committed to providing the best possible treatment for our
            patients at a fair and reasonable cost. Our prices are
            representative of the usual and customary charges for our area. We
            participate with most of the major health insurance plans.
            Understanding your insurance benefits is your responsibility.
            Therefore, please be sure that you verify that our physicians are in
            your network, what your out of network benefits are, and what your
            insurance covers. If you have questions about your coverage, please
            contact your insurance company with any questions before your
            appointment. The patient is responsible for letting us know of
            changes in insurance and other information prior to the appointment.
          </Typography>
          <List>
            {info.map((item) => (
              <Box display="flex" key={item.title}>
                <ListItemButton sx={{ width: 5 }}>
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                </ListItemButton>
                <ListItem>
                  <ListItemText
                    primary={<strong>{item.title}</strong>}
                    secondary={item.description}
                  />
                </ListItem>
              </Box>
            ))}
          </List>
        </Container>
      </Box>
    </Box>
  );
};

export default InsurancePayment;
