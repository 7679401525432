import React from "react";
import MedicalTeamCards from "../components/MedicalTeamCards";
import MedicalTeamHero from "../components/MedicalTeamHero";

const MedicalTeam = () => {
  return (
    <>
      <MedicalTeamHero />
      <MedicalTeamCards />
    </>
  );
};

export default MedicalTeam;
