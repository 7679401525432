import React from "react";
import AboutUs from "../components/AboutUs";
import Contact from "../components/Contact";
import Hero from "../components/Hero";
import OurServices from "../components/OurServices";

const Home = () => {
  return (
    <>
      <Hero />
      <OurServices />
      <AboutUs />
      <Contact />
    </>
  );
};

export default Home;
